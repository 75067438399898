.header{
    display: flex;
    flex-direction: column;
    height: 100vh;
    /* border */
}
.navs{
    width: 100%;
    height: 70px;
    display:flex;
    justify-content: space-between;
    align-items: center;
}
.navs nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.banner{
    flex-grow: 1;
    overflow: hidden !important;
}
.banner section{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10;

}
.banner .headen{
    width: 600px;
}

.bannerBack{
    z-index: 1;
}

@media only screen and (max-width: 1230px){

}