/* FeaturePhone.css */

.feature-phone-container {
  width: 350px;
  height: 700px;
  border: 2px solid #333;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  /*justify-content: center;*/
  font-size: 20px;
  font-weight: bold;
  /*background-color: #f5f5f5;*/
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: auto;
  background-color: #101418
}

.display-screen {
  width: 100%;
  height: 250px;
  background-color: #171d24;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 10px;
  margin-bottom: 10px;
  font-size: 20px;
  border-radius: 4px;
  color: #afb8c4;
}

.assigned-number-area {
  color: #afb8c4;
}

.button-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

button {
  width: 60px;
  height: 60px;
  font-size: 20px;
  background-color: #171d24;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  transition: background-color 0.2s;
}

button:hover {
  background-color: #0b2847;
}

.call-buttons-container {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.phone-options-container {
  display: flex;
  margin: -40px 20px 20px;
  color: #afb8c4;
  justify-content: space-between;
}

.edit-icons-container {
  display: flex;
  margin: -220px 20px 200px;
  color: #afb8c4;
}

.call-button,
.hang-up-button {
  width: 140px;
  height: 40px;
  font-size: 16px;
  border: 1px solid #ccc;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}
